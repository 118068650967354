import axios from 'axios';
import type { AxiosInstance } from 'axios';
import { useRouter } from '#app';
import type { SuccessResponse } from '~/types/response.interfaces';
import { useToastStore } from '~/stores/toast.store';

let instance: AxiosInstance;

export function createAxiosInstance() {
  const { public: env } = useRuntimeConfig();
  const url = `${env.API_URL}/admin/v1`;

  instance = axios.create({
    baseURL: url,
  });

  instance.interceptors.request.use(async (config) => {
    const router = useRouter();
    const controller = new AbortController();
    try {
      const token = await getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    catch {
      controller.abort();
      useToastStore().showInfoToast(
        'Session expired',
        'Your login has expired. Please log in again.',
      );
      await router.push('/login');
    }
    return {
      ...config,
      signal: controller.signal,
    };
  });

  instance.interceptors.response.use(
    (response) => {
      const { message } = response.data as SuccessResponse;
      if (message) useToastStore().showSuccessToast('Success', message);
      return response;
    },
    (err) => {
      return Promise.reject(err);
    },
  );
}

export const client = {

  async get<T>(url: string) {
    const response = await instance.get<T>(url);
    return response.data;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<T>(url: string, data: any) {
    const response = await instance.post<T>(url, data);
    return response.data;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch<T>(url: string, data: any) {
    const response = await instance.patch<T>(url, data);
    return response.data;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put<T>(url: string, data: any) {
    const response = await instance.put<T>(url, data);
    return response.data;
  },

  async delete<T>(url: string) {
    const response = await instance.delete<T>(url);
    return response.data;
  },
};

export const getAccessToken = async (): Promise<string> => {
  const { data } = await useSupabaseClient().auth.getSession();
  const accessToken = data?.session?.access_token;
  if (!accessToken) throw new Error('Access token could not be fetched');
  return accessToken;
};
